import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import AnimationWrapper from "../components/animationWrapper"
import Container from "../components/container"
import AchievementsSection from "../components/achievementsSection"
import IconSection from "../components/iconSection"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import DescriptionSection from "../components/descriptionSection"
import Layout from "../components/layout"

import "./o-firmie.css"

const AboutUsPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="O firmie" data={data.header} />
      </AnimationWrapper>

      <section id="inwestor-desc" className="page-section">
        {data.page.frontmatter.description_sections.map((description_section, key) => (
            <DescriptionSection key={key} location="o-firmie" data={description_section} />
        ))}
      </section>

      <section id="transpol-achievements">
        <Container>
          <AnimationWrapper>
            <AchievementsSection data={data.page.frontmatter.achievements_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="partnerzy-carousel-section" className="page-section">
        <Container>
          <AnimationWrapper className="description-medium">
            <Carousel data={data.page.frontmatter.partnerzy_carousel} location="partnerzy"/>
          </AnimationWrapper>
        </Container>
      </section>

      <section id="homepage-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icon_section} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="klienci-carousel-section" className="page-section">
        <Container>
          <AnimationWrapper className="description-medium">
            <Carousel data={data.page.frontmatter.klienci_carousel} location="klienci"/>
          </AnimationWrapper>
        </Container>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "o-firmie" }, type: { eq: "page" } }) {
      frontmatter{
        title
        description_sections{
          title
          title_visible
          subtitle
          align
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          object_fit
        }
        achievements_section{
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          achievements{
            value
            postfix
            description
          }
        }
        icon_section{
          type
          text_section{
            title
            title_visible
            subtitle
            align
            # image{
            #   childImageSharp{
            #     gatsbyImageData(
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            text
          }
          icons{
            title
            text
            image{
              childImageSharp {
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        partnerzy_carousel{
          text_section{
            title
            title_visible
            # align
            subtitle
            button_text
            button_url
            button_style
            text
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
              id
            }
            description
            status
          }
        }
        klienci_carousel{
          text_section{
            title
            title_visible
            align
            subtitle
            button_text
            button_url
            button_style
            text
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
              id
            }
            description
            status
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-ofirmie.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default AboutUsPage
